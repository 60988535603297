import React, { Component } from 'react';
import ANavigation from "./nav/nav-animated"

class Header extends Component {
  constructor (props) {
    super(props);


    // https://www.w3schools.com/howto/howto_js_navbar_hide_scroll.asp
    if (typeof window !== 'undefined') {
      let prevScrollpos = window.pageYOffset;
      window.onscroll = function () {
        const maxScroll = document.body.clientHeight - window.innerHeight;
        let currentScrollPos = window.pageYOffset;
        if (
            (maxScroll > 0 && prevScrollpos > currentScrollPos && prevScrollpos <= maxScroll) 
          || (maxScroll <= 0 && prevScrollpos > currentScrollPos)
          || (prevScrollpos <= 0 && currentScrollPos <= 0)
          ) {
          document.getElementById("navbar").style.top = "0";
        } else {
          document.getElementById("navbar").style.top = "-5.0rem"; // -5.0rem - adjustable based your need
        }
        prevScrollpos = currentScrollPos;
      }
    }
  }

  render () {
    //const navLocation = this.props.location;
    return (
        <ANavigation navlocation={this.props.navlocation} />
    );
  }
}

export default Header;