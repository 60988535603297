import React from "react";
import { StaticQuery, graphql, Link } from "gatsby";
import { Container, Row, Col } from "reactstrap";
import ALogoFooter from './img/logo-footer'

const FOOTER_QUERY = graphql`
  query SiteFooterQuery {
    site {
      siteMetadata {
        address
        addresstwo
        city
        state
        zip
        phoneFormat
        phone
      }
    }
  }
`
const Footer = props => (
  <StaticQuery
    query={FOOTER_QUERY}
    render={data => {
      return (
          <Container fluid={true} id="colophon" className="site-footer bg-dark pr-0">
            <Row className="footer__content">
            <Col lg={{ size: 2 }} className="footer__logo pb-2">
                <ALogoFooter />
              </Col>
              <Col lg={{ size: 2 }} className="pb-2 pl-3 pl-lg-0">
              
                <p className="footer__address text-white">
                  {data.site.siteMetadata.address}<br/> 
                  {data.site.siteMetadata.addresstwo} 
                  <br/>{data.site.siteMetadata.city}
                  , {data.site.siteMetadata.state} {data.site.siteMetadata.zip}
<br/>
                <a
                  className="footer__phone"
                  href={`tel:${data.site.siteMetadata.phone}`}
                >
                  {data.site.siteMetadata.phoneFormat}
                </a>
                                </p>
              
              </Col>
              <Col lg={{ size: 8 }} className="text-center footer__contact pb-3 pr-1">
              <h4 className="text-white ">How can <span>we help?</span></h4>
              <Link className="btn btn-outline-info" to={`/contact`}>Contact Us </Link>
              
              </Col>
            </Row>
            <Row className="pt-3 footer__content">
            <Col md={{size:4}} className="p-0">
              <p className="footer__byline text-white">All Rights Reserved. &copy; 2020 AHDC <a href="https://primary360.com" rel="noopener noreferrer" target="_blank" className="primary-link">Designed by Primary</a></p>
              </Col>
              <Col md={{size:8}} className="bg-primary footer__greenbar">
              &nbsp;
              </Col>
              </Row>
          </Container>

      );
    }}
  />
);

export default Footer;
