import React from "react";

const ALogoFooter = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="203.627" height="46.662" viewBox="0 0 203.627 46.662">
  <g data-name="Group 26">
    <g data-name="Group 8" fill="#fff">
      <path data-name="Path 10" d="M198.789 33.155l-.86.792c-.882.81-4.283 3.454-11.04 3.454a14.033 14.033 0 01-14.211-13.815c0-8.433 5.357-14.325 13.026-14.325a15.67 15.67 0 0111.313 4.18l.822.776 5.38-7.028-.443-.616c-1-1.387-6.755-6.573-16.677-6.573-13.7 0-24.035 10.043-24.035 23.359 0 13.068 10.879 23.3 24.768 23.3 6.73 0 12.02-1.959 16.167-5.986l.623-.605z"/>
      <path data-name="Path 11" d="M128.571 36.63h-4.272V10.206h5.3c7.493 0 12.727 5.375 12.727 13.07 0 11.618-8.62 13.354-13.757 13.354m-.28-35.853l-15.627.01 1.706 5.743v39.529h12.552c15.954 0 25.866-8.665 25.866-22.613 0-13.558-9.844-22.669-24.5-22.669"/>
    </g>
    <path data-name="Path 12" fill="#fff" d="M58.703.727l1.686 5.595v12.767l11.692-6.593V.727z"/>
    <path data-name="Path 13" fill="#fff" d="M86.578.727L88.29 6.46v5.943l11.712 6.479V.727z"/>
    <path data-name="Path 14" fill="#fff" d="M25.205 14.901l6.551 14.028H18.494zM17.361.729l1.128 5.393L0 46.011h11.251l3.877-8.182h19.907l3.767 8.182h11.774L29.082.729z"/>
    <path data-name="Path 15" fill="#17ac53" d="M60.389 23.727v22.195h11.886V32.547h15.814v13.375H100V23.569L80.189 12.6z"/>
  </g>
</svg>
);

export default ALogoFooter;

