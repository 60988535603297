import React from 'react'
import { Container } from 'reactstrap'
//import ANavigation from "./nav/nav-animated"
import Header from "./header"
import Footer from "./footer"
import "../sass/custom.scss"

const Layout = ({ children, navlocation }) => 
(
    <div id="site">
    <a className="sr-only sr-only-focusable" href="#content">Skip to main content</a>
    <Header navlocation={navlocation} />
    <Container fluid={true} className="container__layout" id="content">
    <main>{children}</main>
    </Container>
    <Footer />
    </div>
  )

export default Layout